import { NavLink } from "react-router-dom";
import {
  FIELD_CONSULTATION_URL,
  FURNITURE_PLANNING_URL,
  ONLINE_CONSULTATION_URL,
  ONLINE_OS_URL,
} from "../config/path-config";

const SERVICES = [
  { name: "Выездная организация пространства", to: FIELD_CONSULTATION_URL },
  { name: "Онлайн организация пространства", to: ONLINE_OS_URL },
  {
    name: "Онлайн консультация “Взгляд профессионала”",
    to: ONLINE_CONSULTATION_URL,
  },
  { name: "Проектирование умной мебели", to: FURNITURE_PLANNING_URL },
];

function Services() {
  return (
    <div className="content services-list">
      {SERVICES.map(({ name, to }, i) => (
        <p key={i}>
          <NavLink to={to}>{name}</NavLink>
        </p>
      ))}
    </div>
  );
}

export default Services;
