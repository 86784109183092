const CONTACTS_URL = "/contacts";
const SERVICES_URL = "/services";
const ONLINE_OS_URL = "/services/online-organization-of-space";
const ONLINE_CONSULTATION_URL = "/services/online-consultation";
const FIELD_CONSULTATION_URL = "/services/field-consultation";
const FURNITURE_PLANNING_URL = "/services/furniture-planning";
const IMPRINT_URL = "/imprint";
const DATA_PROTECTION_URL = "/data-protection";

export {
  CONTACTS_URL,
  SERVICES_URL,
  ONLINE_OS_URL,
  ONLINE_CONSULTATION_URL,
  FIELD_CONSULTATION_URL,
  FURNITURE_PLANNING_URL,
  IMPRINT_URL,
  DATA_PROTECTION_URL,
};
