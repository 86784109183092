function OnlineOS() {
  return (
    <div className="content services-view">
      <h3>Онлайн организация пространства</h3>
      <p>Если ты:</p>
      <p>
        <ul className="marker-list">
          <li>
            живешь далеко от Штутгарта, но нужна моя профессиональная помощь
          </li>
          <li>
            достаточно организована и мотивирована, чтобы действовать
            самостоятельно
          </li>
          <li>хочешь иметь свободный график действий по четкому плану</li>
          <li>легко расстаешься с вещами,</li>
          <li>не хочешь чтобы родные знали, что ты прибегаешь к помощи ОП</li>
        </ul>
      </p>
      <p>то тебе отлично подойдет услуга организации пространства ОНЛАЙН</p>
      <p className="red">
        Стоимость онлайн организации пространства зависит от организуемой зоны,
        ее размера и сложности. От 100 евро за зону.
      </p>
      <p>
        Мы назначаем удобное время для первого видеозвонка, во время которого
        знакомимся, выясняем проблемы, жизненные сценарии. Далее ты делаешь
        фотографии и необходимые замеры комнаты и шкафов. Перед этим звонком не
        нужна никакая подготовка. Убираться не надо!😃 Я должна видеть обычную
        обстановку, чтобы понимать масштабы бедствия и вероятные причины оного.
        Если мы понимаем, что подходим друг другу, то ты оплачиваешь 50 евро и
        после этого я начинаю работать.
      </p>
      <p>
        Я составляю Индивидуальный План Организации (ИПО). Он выглядит как pdf
        презентация с четкими пошаговыми инструкциями к действию, планом
        размещения вещей, рекомендациями и фотореференсами по организационным
        материалам, упрощению ведения домашнего хозяйства, правилам порядка и
        вообще всего, что мне есть сказать по поводу ситуации.
      </p>
      <p>
        Готовый план я высылаю для первого ознакомления. Здесь можно задавать
        вопросы, вносить правки (до 3-х раз), уточнять и тд. Если необходимо
        можем назначить еще один видеосозвон для обсуждения плана. Когда все
        согласовали и тебе все понятно, перечисляешь оставшуюся сумму за проект.
      </p>
      <p>
        В течение следующих двух недель после оплаты у тебя есть моя бесплатная
        поддержка по вотсапу. Поэтому рекомендую не откладывать дело, а начинать
        по возможности сразу.
      </p>

      <p>
        <span className="red">ВАЖНО!</span> Я не подбираю конкретные
        организационные материалы для онлайн проектов. Исходя из опыта работы –
        это огромная и почти всегда бесполезная трата моего времени. Я могу
        потратить часы на поиск одного подходящего по всем техническим
        параметрам органайзера, а тебе он не понравится визуально или не
        подойдет по цене или закончится к моменту покупки. Но я оставляю
        фотопримеры, рекомендации по магазинам и производителям. Если нужна
        какая-то конкретная вещь, то, конечно, я оставляю на нее ссылку.
      </p>
    </div>
  );
}

export default OnlineOS;
