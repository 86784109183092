import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  CONTACTS_URL,
  SERVICES_URL,
  IMPRINT_URL,
  DATA_PROTECTION_URL,
  ONLINE_CONSULTATION_URL,
  ONLINE_OS_URL,
  FIELD_CONSULTATION_URL,
  FURNITURE_PLANNING_URL,
} from "./config/path-config";
import Contacts from "./pages/Contacts";
import DataProtection from "./pages/DataProtection";
import FieldConsultation from "./pages/FieldConsultation";
import FurniturePlanning from "./pages/FurniturePlanning";
import Imprint from "./pages/Imprint";
import Footer from "./pages/layouts/Footer";
import Header from "./pages/layouts/Header";
import Main from "./pages/Main";
import OnlineConsultation from "./pages/OnlineConsultation";
import OnlineOS from "./pages/OnlineOS";
import Services from "./pages/Services";

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path={CONTACTS_URL} element={<Contacts />} />
          <Route path={SERVICES_URL} element={<Services />} />
          <Route path={ONLINE_OS_URL} element={<OnlineOS />} />
          <Route
            path={ONLINE_CONSULTATION_URL}
            element={<OnlineConsultation />}
          />
          <Route
            path={FIELD_CONSULTATION_URL}
            element={<FieldConsultation />}
          />
          <Route
            path={FURNITURE_PLANNING_URL}
            element={<FurniturePlanning />}
          />
          <Route path={IMPRINT_URL} element={<Imprint />} />
          <Route path={DATA_PROTECTION_URL} element={<DataProtection />} />
          <Route path="/" element={<Main />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
