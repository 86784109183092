import { Link } from "react-router-dom";
import { DATA_PROTECTION_URL, IMPRINT_URL } from "../../config/path-config";

function Footer() {
  return (
    <footer>
      <div className="separator" />
      <div className="content footer-content">
        <div>
          <p>
            <Link to={IMPRINT_URL}>Impressum</Link>
          </p>
          <p>
            <Link to={DATA_PROTECTION_URL}>Datenschutzerklärung</Link>
          </p>
        </div>
        <div style={{ flex: "auto" }} />
        <div>
          <p>Einfachornung &#169;2021</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
