function Imprint() {
  return (
    <div className="content imprint">
      <h3>Impressum</h3>
      <p>Ordnungsberaterin Diana Shishkina</p>
      <p>Ziegeleistraße 28</p>
      <p>71384 Weinstadt</p>
      <p>Deutschland</p>
      <h3>Kontakt</h3>
      <p>E-mail: buratinka@gmail.com</p>
    </div>
  );
}

export default Imprint;
