import MainImg from "../assets/mainphoto.jpg";

function Main() {
  return (
    <div className="content about">
      <img className="main-photo" alt="about" src={MainImg} />
      <div>
        <p>
          Привет!
          <br />
          Рада тебя здесь видеть!
        </p>

        <p>
          Меня зовут Диана, я дипломированный организатор пространства и
          специалист по планированию мебели, и я могу создать для тебя
          уникальную систему порядка в доме.
        </p>
        <p>
          Уборка будет занимать минимум времени, все члены семьи будут знать где
          что лежит, никаких опозданий из-за поиска нужной вещи, никаких лишних
          трат на задвоенные вещи. А ты сможешь потратить освободившееся время
          на то, что действительно важно и интересно.
        </p>
      </div>
    </div>
  );
}

export default Main;
