function Contacts() {
  return (
    <div className="content contacts">
      <div>
        <p>
          Е-mail: <a href="mailto:buratinka@gmail.com">buratinka@gmail.com</a>
        </p>
        <p>
          <a href="https://www.instagram.com/di_shishkina">Instagram</a>
        </p>
        <p>
          <a href="https://wa.me/79172640382">WhatsApp</a>
        </p>
      </div>
    </div>
  );
}

export default Contacts;
