import { NavLink } from "react-router-dom";
import { CONTACTS_URL, SERVICES_URL } from "../../config/path-config";

const LINKS = [
  { to: "/", name: "Обо мне" },
  {
    to: CONTACTS_URL,
    name: "Контакты",
  },
  {
    to: SERVICES_URL,
    name: "Услуги",
  },
];

function Header() {
  return (
    <>
      <header>
        <div className="content">
          <h2>Порядок - это легко!</h2>
          <h1>Диана - организатор пространства - Штутгарт</h1>
        </div>
      </header>
      <div className="navigation separator">
        <div className="content">
          <ul className="navigation-content">
            {LINKS.map(({ to, name }, i) => (
              <li key={i} className="item">
                <NavLink to={to}>{name}</NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
