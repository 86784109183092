function DataProtection() {
  return (
    <div className="content data-protection">
      <h3>Datenschutzerklärung</h3>

      <p>Verantwortlicher</p>
      <p>
        Ordnungsberaterin Diana Shishkina
        <br />
        Ziegeleistr. 28
        <br />
        71384 Weinstadt
        <br />
        E-mail: <a href="mailto:buratinka@gmail.com">buratinka@gmail.com</a>
        <br />
      </p>
      <p>
        Wir, Ordnungsberaterin Diana Shishkina, betreiben die Web-Seite
        Einfachordnung.com. Wir nehmen den Schutz Ihrer persönlichen Daten sehr
        ernst und halten uns strikt an die Regeln der Datenschutzgesetze.
      </p>
      <p>
        Die Nutzung unserer Web-Seite ist in der Regel ohne Angabe
        personenbezogener Daten möglich. Soweit auf unseren Seiten
        personenbezogene Daten (beispielsweise Name, Anschrift oder
        E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
        freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
        Zustimmung nicht an Dritte weitergegeben.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <p>
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
        Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
        jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
        Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
        Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
        Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
        gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
        forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
        Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen.
      </p>
      <p>
        Unsere Web-Seite enthält Links zu sozialen Netzwerken wie Instagram und
        Facebook. Wir weisen darauf hin, dass wir keine Kontrolle über die
        Verarbeitung Ihrer Daten durch diese Netzwerke haben und empfehlen
        Ihnen, sich über die Datenschutzrichtlinien dieser Netzwerke zu
        informieren.
      </p>
      <p>
        Wenn Sie Fragen zum Thema Datenschutz haben, können Sie sich jederzeit
        über die auf unserer Web-Seite angegebene Kontaktmöglichkeit an uns
        wenden.
      </p>
    </div>
  );
}

export default DataProtection;
